
import { defineComponent, computed, ref, reactive, Ref, watch } from "vue";
import Dialog from "../../../components/Dialog.vue";
import Valvontaform from "./Valvontaform.vue";
import { useStore } from "vuex";
import { filledFields, formData } from "@/modules/valvontatiedot/utils/form";
import { VALVONTA_TYPES } from "@/modules/valvontatiedot/constants/Valvontatiedot";
import { ROLE } from "@/constants/roles";
import useAuthorisation from "@/utils/useAuthorization";

export default defineComponent({
    components: { Dialog, Valvontaform },
    props: {
        form: Object,
    },
    setup(props) {
        const showError = ref(false);
        const store = useStore();

        const editForm: any = reactive({});
        const removedFiles: Ref<string[]> = ref([]);
        const isUpdating = computed(() => store.state.valvontatiedot.isLoadingValvontatieto);

        watch(
            () => isUpdating.value,
            () => {
                !isUpdating.value && resetForm();
            }
        );

        const modalOpen = ref(false);
        const showDialog = () => {
            const { form } = props;
            const valvontatyyppi: any = VALVONTA_TYPES.find((v) => v.id === form?.valvontatyyppi);
            Object.assign(editForm, {
                ...form,
                publicDocuments: true,
                valvontatyyppi,
                follow_ups: form?.follow_ups || [],
            });
            modalOpen.value = true;
        };

        const hideDialog = () => (modalOpen.value = false);

        function resetForm() {
            Object.assign(editForm, props.form);
            hideDialog();
        }

        const hasFilledFields = computed(() => {
            return filledFields(editForm);
        });
        function checkError() {
            if (hasFilledFields.value) {
                saveForm();
            } else {
                showError.value = true;
            }
        }

        function saveForm() {
            const valvontadata: any = formData(editForm);
            const { files, ...valvontatiedot } = valvontadata;
            const { follow_ups, needs_follow_up } = valvontatiedot;
            const data = new FormData();
            data.append(
                "valvontatiedot",
                JSON.stringify({
                    ...valvontatiedot,
                    uuid: editForm.uuid,
                    follow_ups: needs_follow_up ? follow_ups : [],
                })
            );
            data.append("removedFiles", JSON.stringify(removedFiles.value));
            const newFiles = editForm.files.filter((f: any) => !f.uuid);

            newFiles.forEach((file: File) => {
                data.append("files[]", file, file.name);
            });
            store.dispatch("valvontatiedot/editValvontatiedot", data);
        }

        function onFileChanged(event: any) {
            const newFiles: File[] = [...editForm.files];
            event.target.files.forEach((file: File) => {
                newFiles.push(file);
            });
            editForm.files = newFiles;
        }

        function removeFile(index: number, uuid?: string) {
            const newFiles = [...editForm.files];
            newFiles.splice(index, 1);
            editForm.files = newFiles;
            if (uuid) {
                removedFiles.value.push(uuid);
            }
        }

        const allowEdit = computed(() => {
            const { canAccess, organisationMatches } = useAuthorisation(
                [
                    ROLE.TYONTEKIJA,
                    ROLE.KUNTAMANAGER,
                    ROLE.VALVOJA,
                    ROLE.VALVOJAMANAGER,
                    ROLE.SIHTEERI,
                ],
                props.form?.organisaatio.id
            );
            return canAccess && organisationMatches;
        });

        return {
            editForm,
            modalOpen,
            showDialog,
            hideDialog,
            resetForm,
            saveForm,
            hasFilledFields,
            onFileChanged,
            removeFile,
            checkError,
            showError,
            isUpdating,
            allowEdit,
        };
    },
});
