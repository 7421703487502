
import { defineComponent, computed, Ref, ref, watch, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ValvontaHakutulos from "../components/ValvontaHakutulos.vue";
import AddValvontatieto from "../components/AddValvontatieto.vue";
import ValvontaHaku from "../components/ValvontaHaku.vue";
import Valvontatieto from "../components/Valvontatieto.vue";
import { ValvontatiedotSearchResult } from "../@types/ValvontatiedotSearchResult";
import { ROLE } from "@/constants/roles";
import useAuthorisation from "@/utils/useAuthorization";

export default defineComponent({
    components: { AddValvontatieto, ValvontaHaku, ValvontaHakutulos, Valvontatieto },
    setup() {
        const store = useStore();
        const selectedResultUuid: Ref<string | null> = ref(null);
        const route = useRoute();

        const status = reactive({
            clean: true,
        });

        const cameFromPalveluhaku = computed(() => route.query.cameFrom === "palveluhaku");

        const palveluyksikkoIdParam = !isNaN(Number(route.params.palveluyksikkoId))
            ? route.params.palveluyksikkoId
            : null;
        if (palveluyksikkoIdParam) {
            status.clean = false;
            store.dispatch("valvontatiedot/haku", {
                searchParams: {
                    palveluyksikko_id: palveluyksikkoIdParam,
                },
            });
        }
        const showResults: Ref<boolean> = ref(false);

        const hakuResults: Ref<ValvontatiedotSearchResult[]> = computed(
            () => store.state.valvontatiedot.valvontatiedot
        );

        const valvontatiedot: Ref<ValvontatiedotSearchResult[]> = computed(() =>
            !showResults.value ? [] : hakuResults.value
        );

        watch(hakuResults, () => {
            showResults.value = true;
        });

        const isLoadingResults = computed(() => store.state.valvontatiedot.isLoadingResults);

        const selectedResult: Ref<ValvontatiedotSearchResult | undefined> = computed(() =>
            valvontatiedot.value.find(
                (v: ValvontatiedotSearchResult) => v.uuid === selectedResultUuid.value
            )
        );

        const onResultClick = (uuid: string) => {
            selectedResultUuid.value = uuid;
        };

        const onSearch = () => {
            status.clean = false;
        };

        const { canAccess: canAccessAddValvontatieto } = useAuthorisation([
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.SIHTEERI,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
        ]);

        const { clean } = toRefs(status);

        return {
            valvontatiedot,
            selectedResult,
            onResultClick,
            isLoadingResults,
            palveluyksikkoIdParam,
            canAccessAddValvontatieto,
            cameFromPalveluhaku,
            clean,
            onSearch,
        };
    },
});
