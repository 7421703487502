
import { defineComponent, PropType, computed } from "vue";
import { ValvontatiedotSearchResult } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchResult";
import { formattedDate } from "@/utils/date";

export default defineComponent({
    emits: ["click"],
    props: {
        result: {
            type: Object as PropType<ValvontatiedotSearchResult>,
            required: true,
        },
        showUpdated: Boolean,
    },
    setup(props) {
        const name = computed(() => props.result.palveluyksikko.name);
        const date = computed(() => formattedDate(props.result.control_date));
        const palveluntuottaja = computed(() => props.result.palvelutuottaja.name);

        return { name, date, palveluntuottaja };
    },
});
