
import { defineComponent, computed, ref, reactive } from "vue";
import Dialog from "@/components/Dialog.vue";
import Valvontaform from "./Valvontaform.vue";
import { filledFields, formData } from "@/modules/valvontatiedot/utils/form";
import { useStore } from "vuex";

export default defineComponent({
    components: { Dialog, Valvontaform },
    setup() {
        const initialState: any = {
            palveluyksikko: null,
            follow_ups: [],
            needs_follow_up: null,
            valvontatyyppi: null,
            attachments_contain_restricted_material: false,
            publicDocuments: false,
            control_date: "",
            author: "",
            organisaatio: null,
            author_email: "",
            author_phone: "",
            files: [],
            huomioitavaa: false,
        };
        const showError = ref(false);
        const store = useStore();

        const userOrganisation = computed(() => store.state.user.organisation);

        const form = reactive({ ...initialState, organisaatio: userOrganisation });

        const modalOpen = ref(false);
        const showDialog = () => (modalOpen.value = true);
        const hideDialog = () => (modalOpen.value = false);

        function resetForm() {
            Object.assign(form, initialState);
            hideDialog();
        }

        const hasFilledFields = computed(() => {
            return filledFields(form);
        });
        function checkError() {
            if (hasFilledFields.value) {
                saveForm();
            } else {
                showError.value = true;
            }
        }

        function saveForm() {
            const valvontatiedot = formData(form);

            const data = new FormData();
            data.append("valvontatiedot", JSON.stringify(valvontatiedot));
            form.files.forEach((file: File) => {
                data.append("files[]", file, file.name);
            });

            store.dispatch("valvontatiedot/saveValvontatiedot", data);
            resetForm();
            hideDialog();
        }

        function onFileChanged(event: any) {
            const newFiles: File[] = [...form.files];
            event.target.files.forEach((file: File) => {
                newFiles.push(file);
            });
            form.files = newFiles;
        }

        function removeFile(index: number) {
            const newFiles = [...form.files];
            newFiles.splice(index, 1);
            form.files = newFiles;
        }

        return {
            form,
            modalOpen,
            showDialog,
            hideDialog,
            resetForm,
            saveForm,
            hasFilledFields,
            onFileChanged,
            removeFile,
            checkError,
            showError,
        };
    },
});
