
import { defineComponent, PropType } from "vue";
import Header from "@/modules/valvontatiedot/components/Header.vue";
import { ValvontatiedotSearchResult } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchResult";
import { VALVONTA_TYPES } from "@/modules/valvontatiedot/constants/Valvontatiedot";

export default defineComponent({
    components: { Header },
    emits: ["click"],
    props: {
        result: {
            type: Object as PropType<ValvontatiedotSearchResult>,
            required: true,
        },
        selected: Boolean,
    },
    setup() {
        const typeName = (value: string): string | undefined => {
            return VALVONTA_TYPES.find((t) => t.id === value)?.name;
        };
        return { typeName };
    },
});
