import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-39a10ea8")
const _hoisted_1 = { class: "valvontahaku" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValvontaHakuForm = _resolveComponent("ValvontaHakuForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ValvontaHakuForm, _mergeProps(_ctx.$attrs, {
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form = $event)),
      "on-click": _ctx.onClick,
      "show-huomioitavaa": ""
    }), null, 16, ["modelValue", "on-click"])
  ]))
}