
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import ValvontaHakuForm from "@/components/ValvontaHakuForm.vue";
import { formattedDateRange } from "@/utils/date";
import { ValvontahakuForm } from "@/modules/valvontatiedot/@types/ValvontahakuForm";
import { mapOptionToId } from "@/utils/option";

export default defineComponent({
    components: {
        ValvontaHakuForm,
    },
    props: {
        palveluyksikkoId: { type: String, required: false },
    },
    emits: ["click", "search"],
    setup(props, { emit }) {
        const store = useStore();
        const initialState: ValvontahakuForm = {
            palveluyksikko: store.getters.getPalveluyksikkoById(props.palveluyksikkoId)?.name,
            location: null,
            valvontatyyppi: null,
            date: null,
            organisaatio: null,
            huomioitavaa: false,
        };

        const form = reactive({ ...initialState });
        const date = computed(() => {
            if (form.date === null) {
                return null;
            }
            return formattedDateRange(form.date);
        });

        const onClick = () => {
            const parsedForm = {
                valvontatyyppi: form.valvontatyyppi?.id || null,
                palveluyksikko_name: form.palveluyksikko,
                start: form.date?.from || null,
                end: form.date?.to || null,
                location: form.location ? mapOptionToId(form.location) : null,
                organisaatio: form.organisaatio ? mapOptionToId(form.organisaatio) : null,
                huomioitavaa: form.huomioitavaa,
            };
            store.dispatch("valvontatiedot/haku", { searchParams: parsedForm });
            emit("search");
        };

        return { form, date, onClick };
    },
});
