import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-69bc8fef")
const _hoisted_1 = { class: "row justify-center items-center" }
const _hoisted_2 = {
  key: 0,
  class: "pill-button bg-secondary-light-3 q-mr-md"
}
const _hoisted_3 = {
  key: 1,
  class: "pill-button bg-primary-light-3"
}
const _hoisted_4 = { class: "row justify-between q-pt-sm" }
const _hoisted_5 = { class: "title text-dark-title" }
const _hoisted_6 = { class: "title text-dark-title" }
const _hoisted_7 = { class: "overline text-weight-bold uppercase" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.result.huomioitavaa)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Huomioitavaa "))
        : _createCommentVNode("", true),
      (_ctx.result.follow_ups?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Seurattavaa "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.date), 1)
      ])
    ]),
    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.palveluntuottaja), 1)
  ], 64))
}