import { formatDate, localStringToDate } from "@/utils/date";

export const filledFields = (form: any) => {
    return (
        form.author &&
        form.palveluyksikko &&
        form.valvontatyyppi &&
        localStringToDate(form.control_date) &&
        form.author_phone &&
        form.author_email &&
        (form.needs_follow_up === false || form.needs_follow_up)
    );
};

export const formData = (form: any) => {
    const {
        follow_ups,
        needs_follow_up,
        attachments_contain_restricted_material,
        control_date,
        author,
        author_email,
        author_phone,
        huomioitavaa,
    } = form;
    return {
        palveluyksikko_id: form.palveluyksikko?.id,
        follow_ups,
        needs_follow_up,
        valvontatyyppi: form.valvontatyyppi?.id,
        attachments_contain_restricted_material,
        control_date: formatDate(localStringToDate(control_date)!, "YYYY-MM-DD"),
        author,
        author_phone,
        author_email,
        huomioitavaa,
    };
};
