
import { defineComponent, PropType, computed, ref, Ref } from "vue";
import DateInput from "@/components/input/DateInput.vue";
import PalveluyksikotInput from "@/components/PalveluyksikotInput.vue";
import { formattedDate } from "@/utils/date";
import ValvontatyyppiInput from "@/modules/valvontatiedot/components/input/ValvontatyyppiInput.vue";
import { SEURATTAVAA_TYPES } from "../constants/Valvontatiedot";
import { fileUrl } from "@/modules/valvontatiedot/api/file";
import { ValvontahakuForm } from "../@types/ValvontahakuForm";
import { localStringToDate } from "@/utils/date";

export default defineComponent({
    components: { DateInput, PalveluyksikotInput, ValvontatyyppiInput },
    props: {
        modelValue: Object as PropType<ValvontahakuForm>,
        removeFile: Function,
        onFileChanged: Function,
        showError: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const seurattavaaClasses = computed(() =>
            props.showError && form.value.needs_follow_up === null ? "error" : ""
        );

        const seurattavaaOptions = [
            { label: "Ei", value: false },
            { label: "Kyllä", value: true },
        ];
        const seurattavaaTypes = SEURATTAVAA_TYPES;
        const fileUpload: Ref<HTMLElement | null> = ref(null);
        const publicDocumentsError = ref(false);

        function onFileUploadClick() {
            if (!form?.value.publicDocuments) {
                publicDocumentsError.value = true;
            } else {
                fileUpload.value?.click();
            }
        }

        function downloadFile(file: File) {
            const { type, name } = file;
            const blob = new Blob([file], { type });
            const objectURL = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = objectURL;
            a.setAttribute("download", name);
            a.click();
        }

        const getFileUrl = fileUrl;

        return {
            form,
            seurattavaaTypes,
            onFileUploadClick,
            downloadFile,
            fileUpload,
            publicDocumentsError,
            seurattavaaOptions,
            getFileUrl,
            seurattavaaClasses,
            localStringToDate,
        };
    },
});
