import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Valvontaform = _resolveComponent("Valvontaform")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      "modal-open": _ctx.modalOpen,
      title: "Muokkaa valvontatietoja",
      "on-cancel": _ctx.resetForm,
      "on-save": _ctx.checkError,
      "hide-dialog": _ctx.hideDialog,
      "save-disabled": false,
      "custom-class": "valvonta-dialog",
      loading: _ctx.isUpdating
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Valvontaform, _mergeProps(_ctx.$attrs, {
          modelValue: _ctx.editForm,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.editForm = $event)),
          "on-file-changed": _ctx.onFileChanged,
          "remove-file": _ctx.removeFile,
          "show-error": _ctx.showError
        }), null, 16, ["modelValue", "on-file-changed", "remove-file", "show-error"])
      ]),
      _: 1
    }, 8, ["modal-open", "on-cancel", "on-save", "hide-dialog", "loading"]),
    (_ctx.allowEdit)
      ? (_openBlock(), _createBlock(_component_q_btn, {
          key: 0,
          color: "secondary",
          icon: "fas fa-edit",
          "no-caps": "",
          label: "muokkaa",
          onClick: _ctx.showDialog
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}