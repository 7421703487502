
import { defineComponent, PropType, computed } from "vue";
import { ValvontatiedotSearchResult } from "@/modules/valvontatiedot/@types/ValvontatiedotSearchResult";
import {
    VALVONTA_TYPES,
    SEURATTAVAA_TYPES,
} from "@/modules/valvontatiedot/constants/Valvontatiedot";
import { formattedDate } from "@/utils/date";
import { fileUrl } from "@/modules/valvontatiedot/api/file";
import EditValvontatieto from "../components/EditValvontatieto.vue";

export default defineComponent({
    components: { EditValvontatieto },
    emits: ["click"],
    props: {
        result: {
            type: Object as PropType<ValvontatiedotSearchResult>,
            required: true,
        },
        selected: Boolean,
    },
    setup(props) {
        const typeName = computed(
            () => VALVONTA_TYPES.find((t) => t.id === props.result.valvontatyyppi)?.name
        );

        const date = computed(() => formattedDate(props.result.control_date));

        const seurattavaaTypeName = (value: string): string | undefined => {
            return SEURATTAVAA_TYPES.find((t) => t.id === value)?.name;
        };

        return { typeName, date, seurattavaaTypeName, fileUrl };
    },
});
